import { CampaignIframeOverlayConstants } from '../View/exportComponent';

export function filterMethod(object, type) {
    return object?.filter(item => item[type]);
}

export const interestitialEventPush = (eventCategory, eventAction, eventLabel) => {
    if (typeof (window.dataLayer) !== 'undefined') {

        let eventObj = {
                'event': CampaignIframeOverlayConstants?.customEvent,
                'GAeventCategory': eventCategory,
                'GAeventAction': eventAction
        }

        if(eventLabel) {
            eventObj['GAeventLabel'] = eventLabel;
        }

        window.dataLayer.push(eventObj);
    }   
};